<script>
import { useRoute } from 'vue-router'
import { regFenToYuan, regYuanToFen, encodeValue, decodeValue } from '@/utils'
export default {
  setup() {
    let route = useRoute()
    let amountParams = decodeValue(route.query.v)
    return {
      amountParams,
      regFenToYuan
    }
  }
    
}
</script>
<template>
  <div class="page">
    <div class="box">
      <p><img src="@/assets/images/icon-success.png" style="width:2rem; height:2rem; margin:.5rem 0" /></p>
      <p class="desc" style="padding-bottom:.5rem">支取发起成功，请耐心等待审核结果<br />预计到账时间24-48小时</p>
    </div>
    <div class="box">
      <div class="result" v-if="amountParams">
        <p>
          <span>支取金额</span>
          <span>￥{{regFenToYuan(amountParams.withdrawalAmount)}}</span>
        </p>
        <p>
          <span>到账银行卡</span>
          <span>{{amountParams.bankName}}({{amountParams.bankNo}})</span>
        </p>
        <p>
          <span>手续费</span>
          <span>￥{{regFenToYuan(amountParams.serviceCharge)}}(免手续费额度：{{regFenToYuan(amountParams.freeServiceCharge)}}元)</span>
        </p>
        <p>
          <span>个税减扣</span>
          <span>￥{{regFenToYuan(amountParams.taxCharge)}}</span>
        </p>
        
      </div>
      
    </div>
    <div style="margin-top:.5rem; padding:0 .4rem">
      <van-button block type="primary" native-type="submit" @click="$router.push('/')">完成</van-button>
    </div>
  </div>
</template>
<style scoped>

  .page {  text-align:center }
  .box { overflow: hidden; margin-bottom: .2rem; background:#fff }
  .desc { line-height:1.7 }
   .result { padding: 0 .4rem .4rem; }
  .result p { margin-top: .45rem; display: table; width: 100%; font-size: .3rem}
  .result p span { display:table-cell; text-align:justify }
  .result p span:last-child { text-align:right }
</style>